<template>
  <div>
    <div class="row">
      <div class="col-3 ml-2 p-0">
        <el-select v-model="information"  placeholder="Information"  class="w-100" >
          <el-option value="URLActive" label="Active Shared URLs">Active Shared URLs</el-option>
          <el-option value="usersWithNotifications" label="URLs with uploaded files">URLs with uploaded files</el-option>
        </el-select>
      </div>
    </div>
    <modal-due-date ref="modal-due-date" v-on:refresh="loadUrl($event)"></modal-due-date>
    <el-table v-show="information=='URLActive'"
    :data="tableDataURLActive" 
    class="w-100">
      <el-table-column
        label="Url"
        width="140">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="left">
            <p>Url: {{ scope.row.url }}</p>
            <div slot="reference" class="name-wrapper">
              <el-button icon="fal fa-copy" type="primary" @click="copy_url(scope.row.url)"> Copy Url</el-button>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="150" sortable prop="shareDate" label="Share Date">
        <template slot-scope="scope">{{ scope.row.shareDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column width="300" sortable prop="dueDate" label="Due Date">
        <template slot-scope="scope">
          {{ scope.row.dueDate | moment("MM-DD-YYYY") }}
          <el-button icon="fal fa-edit" class="ml-2" @click="$refs['modal-due-date'].startDuedate(scope.row,scope.$index)"> Change Due Date</el-button>
        </template>
      </el-table-column>
      <el-table-column label="Emails">
        <template slot-scope="scope">
          {{scope.row.emails.map(item=>item.email).join(" ,")}}
        </template>
      </el-table-column>
      <el-table-column label="Operations" width="120">
        <template slot-scope="scope">
          <el-button-group>
            <el-button icon="el-icon-delete" @click="deactivate(scope.row,scope.$index)"> Detele</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-show="information=='usersWithNotifications'"
    :data="tableDataUsersWithNotifications" 
    class="w-100">
      <el-table-column
        label="Url"
        width="140">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="left">
            <p>Url: {{ scope.row.url }}</p>
            <div slot="reference" class="name-wrapper">
              <el-button icon="fal fa-copy" type="primary" @click="copy_url(scope.row.url)"> Copy Url</el-button>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="150" sortable prop="shareDate" label="Share Date">
        <template slot-scope="scope">{{ scope.row.shareDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column width="150" sortable prop="dueDate" label="Due Date">
        <template slot-scope="scope">{{ scope.row.dueDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column label="Emails">
        <template slot-scope="scope">
          {{scope.row.emails.map(item=>item.email).join(" ,")}}
        </template>
      </el-table-column>
      <el-table-column label="Operations" width="200">
        <template slot-scope="scope">
          <el-button-group>
            <el-button icon="el-icon-search" @click="viewNotifications(scope.row)"> View Notifications</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-show="information=='NotificationsOfUser'"
    :data="tableDataNotificationsOfUser" 
    class="w-100">
      <el-table-column sortable prop="uploadUserName" label="User Name"></el-table-column>
      <el-table-column sortable prop="uploadDirectory" label="Location Folder"></el-table-column>
      <el-table-column sortable prop="fileName" label="File Name"></el-table-column>
      <el-table-column sortable prop="targetFolder" label="Target Folder"></el-table-column>
      <el-table-column width="150" sortable prop="uploadDate" label="Upload Date">
        <template slot-scope="scope">{{ scope.row.uploadDate | moment("MM-DD-YYYY") }}</template>
      </el-table-column>
      <el-table-column label="Operations" width="100">
        <template slot-scope="scope">
          <el-button-group>
            <el-button icon="el-icon-success" @click="completeNotification(scope.row,scope.$index)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>
import documentManager from "@/services/api/documentManager";
import ModalDueDate from "@/components/document-manager/panel/ModalDueDate";

export default {
  name: 'outbound-panel',
  data() {
    return {
      tableDataURLActive: [],
      tableDataUsersWithNotifications: [],
      tableDataNotificationsOfUser: [],
      search:"",
      information:'URLActive',
      discriminator:'guest'
    };
  },
  components: {
      ModalDueDate
    },
  mounted() {
    documentManager.getActiveUrls().then(response => {
      this.tableDataURLActive = response.result.info;
    });
    documentManager.getUsersWithNotifications({discriminator:this.discriminator}).then(response => {
      this.tableDataUsersWithNotifications = response.result.info;
    });
  },
  methods: {
    loadUrl(event){
      this.tableDataURLActive.splice(event.index, 1, event.data);
    },
    copy_url(url){
      navigator.clipboard.writeText(url).then(() => {
        this.$message({
                message: 'Copying to clipboard was successful!',
                type: "success",
                customClass: "message-success"
              });
      }).catch(() =>{
          this.$message({
                message: 'Could not copy text!',
                type: "error",
                customClass: "message-error"
              });
      });
    },
    deactivate(row, index) {
      this.$swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#67C23A',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, deactivate it!'
        }).then((result) => {
          if (result.value) {
            documentManager
            .disableUrl({id:row.id})
            .then(response => {
              this.tableDataURLActive.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    },
    viewNotifications(row){
      this.information='NotificationsOfUser';
      documentManager
        .uploadNotificationsOfUser({id:row.id})
        .then(response => {
          this.tableDataNotificationsOfUser=response.result.notifications;
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error"
          });
        });
    },
    completeNotification(row, index){
      this.$swal({
          title: 'Are you sure?',
          text: "The Notification Will Be Disapear!",
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.value) {
            documentManager
            .disableUploadNotification({id:row.id})
            .then(response => {
              this.tableDataNotificationsOfUser.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error"
              });
            });
          }
        });
    }
  }
};
</script>
